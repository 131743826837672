var assign = Object.assign;
var Promise = (typeof window !== 'undefined' && window.Promise) || global.Promise;
export { v4 as uuid } from 'uuid';
export * from './logging/';
export * from './messaging/';
export { Promise, assign };
export { default as Future } from './Future';
export { default as getQueryParams } from './getQueryParams';
export { default as mergeQueryParams } from './mergeQueryParams';
export * from './cookies';
export { default as getSecondLevelDomain } from './getSecondLevelDomain';
export { default as fetchJSON } from './fetchJSON';
export { default as getClientsideVisitorId } from './getClientsideVisitorId';
export { default as setClientsideVisitorIdCookie } from './setClientsideVisitorIdCookie';
export { default as determineActualUrl } from './determineActualUrl';
export { default as getTrackingEndpointUrl } from './getTrackingEndpointUrl';
