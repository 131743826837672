import { v4 as uuid } from 'uuid';
import { getCookie, setCookie } from './cookies';
var setClientsideVisitorIdCookie = function (domain) {
    var cookieName = 'c_visitor_id';
    var options = {
        domain: domain,
    };
    if (!getCookie(cookieName)) {
        setCookie(cookieName, uuid(), 1000 * 5, options); // Cookie expires after 5 seconds
    }
};
export default setClientsideVisitorIdCookie;
