import { getSecondLevelDomain } from './';
var getTrackingEndpointUrl = function (config, env, path) {
    var host = config.host;
    var secondLevelDomain = getSecondLevelDomain();
    var nonAntsSecondLevelDomains = [
        'xing.com',
        'csod.com',
        'onlyfy.dev',
        'localhost',
        'test.local',
        null,
    ];
    if (secondLevelDomain &&
        !nonAntsSecondLevelDomains.includes(secondLevelDomain)) {
        host =
            env === 'production'
                ? "ants.".concat(secondLevelDomain)
                : "preview-ants.".concat(secondLevelDomain);
    }
    return "https://".concat(host).concat(path);
};
export default getTrackingEndpointUrl;
