import getConfig from './config';
import { TTT_VERSION as version } from '../../../version';
import { getChannel, getApplicationByDomain, postTracking, sendBeacon, isBeaconApiSupported, } from '../helper';
import { Promise, getTrackingEndpointUrl, getSecondLevelDomain, setClientsideVisitorIdCookie, } from '../../../lib';
import { NWT_TRACKING_PATH } from '../../../constants';
var firstEvent = true;
var tracker = {
    track: function (event, env, url) {
        if (firstEvent) {
            setClientsideVisitorIdCookie(getSecondLevelDomain());
            firstEvent = false;
        }
        sendEvent(event, getTrackingEndpointUrl(getConfig(env), env, NWT_TRACKING_PATH), url);
    },
};
var sendEvent = function (eventData, endpointUrl, screenUrl) {
    var payload = Array.isArray(eventData) ? eventData : [eventData];
    payload.forEach(function (event) {
        event.channel = event.channel || getChannel(getSecondLevelDomain());
        event.application =
            event.application || getApplicationByDomain(getSecondLevelDomain());
        event.event_timestamp = Date.now();
        event.screen_url = "".concat(screenUrl.hostname).concat(screenUrl.pathname).concat(screenUrl.search);
        event.screen_domain = "".concat(screenUrl.hostname);
        event.screen_resolution =
            window.screen && "".concat(window.screen.width, "x").concat(window.screen.height);
        event.sdk_name = "ttt".concat(event.sdk_name ? ',' + event.sdk_name : '');
        event.sdk_version = "".concat(version).concat(event.sdk_version ? ',' + event.sdk_version : '');
        event.referrer = document.referrer;
    });
    return new Promise(function (resolve) {
        if (isBeaconApiSupported()) {
            resolve(sendBeacon(endpointUrl, payload));
        }
        else {
            resolve(postTracking(endpointUrl, payload));
        }
    });
};
export default tracker;
