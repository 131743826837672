import fetchJson from '@xingternal/malt-network/fetch-json';

import messages from './messages';

import { JSON_HEADERS } from './constants';

const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const validateUsername = (username) => {
  const { usernameRequiredError, emailInvalidError } = messages;
  const trimmedUsername = username.trim();

  if (trimmedUsername === '') {
    return usernameRequiredError;
  }

  if (!isValidEmail(trimmedUsername)) {
    return emailInvalidError;
  }

  return null;
};
const validateLogin = (values) => {
  const { usernameRequiredError, passwordRequiredError, emailInvalidError } =
    messages;
  const errors = {};
  const usernameError = validateUsername(values.username);
  if (usernameError) {
    errors.username = usernameError;
  }
  if (values.password.trim() === '') {
    errors.password = passwordRequiredError;
  }

  return errors;
};

const createLogin = (externalLoginUrl, values, retries = 2) => {
  return loginRequest(externalLoginUrl, values)
    .then((result) => result)
    .catch((error) => {
      if (retries === 1) throw error;

      // skip retry for wrong credentials
      const status = error.response && error.response.status;
      if (status <= 401) throw error;

      return createLogin(externalLoginUrl, values, retries - 1);
    });
};

const loginRequest = (externalLoginUrl, values) =>
  fetchJson(`${externalLoginUrl}/login/api/login`, {
    headers: JSON_HEADERS,
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify(values),
  }).then(({ success, target, auth_token, logged_out_sid }) => {
    if (!success) throw Error;
    if (!auth_token) {
      return new Promise(() => {
        if (target.includes('/deactivated')) {
          localStorage.setItem('isDeactivated', 'true');
        }
        window.location = `${target}`;
      });
    }
    fetchJson(`${target}`, {
      headers: JSON_HEADERS,
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        auth_token: `${auth_token}`,
        logged_out_sid: `${logged_out_sid}`
      }),
    }).then(({success, target}) => {
      if (!success) throw Error;
      return new Promise(() => {
        if (target.includes('/deactivated')) {
          localStorage.setItem('isDeactivated', 'true');
        }
        window.location = `${target}`;
      });
    });
  });

const createOnlyfySignup = async (externalUrl, values) =>
  fetchJson(`${externalUrl}/signup/oauth/onlyfy`, {
    headers: JSON_HEADERS,
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify(values),
  }).then(({ success }) => {
    if (!success || !success.login_url) throw Error;
    return new Promise(() => {
      window.location = success.login_url;
    });
  });

export { validateLogin, createLogin, createOnlyfySignup };
