import { v4 as uuid } from 'uuid';
import { buildMessage } from './helpers';
import subscriber from './subscriber';
var publisher = function (window, target, origin) {
    if (origin === void 0) { origin = '*'; }
    return function (action, payload, options) {
        if (options === void 0) { options = {}; }
        var id = options.id || uuid();
        var message = buildMessage({ id: id, action: action, payload: payload });
        target.postMessage(JSON.stringify(message), '*');
        var _a = options.fireAndForget, fireAndForget = _a === void 0 ? false : _a, _b = options.timeout, timeout = _b === void 0 ? 10000 : _b, _c = options.fullResponse, fullResponse = _c === void 0 ? false : _c;
        if (fireAndForget === true) {
            return Promise.resolve();
        }
        else {
            return new Promise(function (resolve, reject) {
                var subscribe = subscriber(window);
                var timeoutHandler = setTimeout(function () {
                    reject(new Error("response for '".concat(action, "' has timed out")));
                }, timeout);
                var unsubscribe = subscribe("response:".concat(action, ":").concat(id), function (response) {
                    unsubscribe();
                    if (fullResponse) {
                        resolve(response);
                    }
                    else {
                        var error = response.error, payload_1 = response.payload;
                        if (error) {
                            reject(error);
                        }
                        else {
                            resolve(payload_1);
                        }
                    }
                    clearTimeout(timeoutHandler);
                    return true;
                }, { fullResponse: true });
            });
        }
    };
};
export default publisher;
