var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getCookie, setCookie, getSecondLevelDomain, determineActualUrl, } from '../lib';
import getPerformanceMetrics from './getPerformanceMetrics';
import createSandbox from './createSandbox';
import clearParams from './clearParams';
import { API_VERSION, TTT_VERSION, PARAM_REGEX, DEDUPLICATION_ID_ALLOWLIST, } from '../constants';
import abacus from './trackers/abacus';
import nwt from './trackers/nwt';
import { v4 as uuid } from 'uuid';
var isRegex = function (value) { return value instanceof RegExp; };
var isString = function (value) { return typeof value === 'string'; };
var createClearParamsFilter = function (clearParams) {
    if (typeof clearParams === 'function') {
        return clearParams;
    }
    else if (clearParams === true) {
        return function (param) { return PARAM_REGEX.test(param); };
    }
    else if (isRegex(clearParams)) {
        return function (param) { return clearParams.test(param); };
    }
    else if (isString(clearParams)) {
        return function (param) { return clearParams === param; };
    }
    else {
        return null;
    }
};
var splitConfigs = function (env, config) {
    // filter ttt only configuration
    var clearParams = config.clearParams, host = config.host, sandboxFile = config.sandboxFile, sandboxUrl = config.sandboxUrl, stmConfig = __rest(config, ["clearParams", "host", "sandboxFile", "sandboxUrl"]);
    var tttConfig = {
        clearParams: clearParams,
        host: host,
        sandboxFile: sandboxFile,
        sandboxUrl: sandboxUrl,
    };
    return [buildTTTConfig(env, tttConfig), buildSTMConfig(env, stmConfig)];
};
var buildSTMConfig = function (env, config) {
    var search = (window.location || {}).search;
    return Object.assign({
        debug: /debug=true/.test(search),
    }, config, {
        env: env,
    });
};
var buildTTTConfig = function (env, config) {
    var clearParams = config.clearParams, _a = config.host, sandboxHost = _a === void 0 ? env === 'production'
        ? 'stan.xing.com'
        : 'preview-stan.xing.com' : _a, _b = config.sandboxFile, sandboxFile = _b === void 0 ? "stm-".concat(API_VERSION, ".html") : _b, _c = config.sandboxUrl, sandboxUrl = _c === void 0 ? "https://".concat(sandboxHost, "/").concat(sandboxFile) : _c;
    return { clearParams: clearParams, sandboxUrl: sandboxUrl };
};
var throwInitError = function () {
    throw new Error('Please call ttt.init first');
};
var setupTracking = function (window) {
    var ttt = {
        version: TTT_VERSION,
        baseData: {},
        _dl: {},
        _sandbox: createSandbox(window),
        _clearParamsFilter: undefined,
        _initCalled: false,
        _env: undefined,
    };
    var assertInitialization = function () {
        if (!ttt._initCalled) {
            throwInitError();
        }
    };
    ttt.assign = Object.assign;
    ttt.init = function (env, config) {
        if (config === void 0) { config = {}; }
        // return silently, because it should be possible to call init multiple times
        if (ttt._initCalled)
            return Promise.resolve();
        ttt._initCalled = true;
        ttt._env = env;
        var _a = splitConfigs(env, config), tttConfig = _a[0], stmConfig = _a[1];
        var _b = tttConfig.clearParams, clearParams = _b === void 0 ? true : _b, sandboxUrl = tttConfig.sandboxUrl;
        ttt._sandbox.load(sandboxUrl);
        if (clearParams) {
            ttt._clearParamsFilter = createClearParamsFilter(clearParams);
        }
        return ttt._sandbox.publish('stm:init', stmConfig);
    };
    ttt.pageview = function (channel, pageName, baseData, contextData) {
        assertInitialization();
        // copy base data for later usage
        ttt.baseData = Object.assign({}, baseData);
        // create the data layer for events
        ttt._dl = Object.assign({}, baseData, {
            PropChannel: channel,
            pageName: pageName,
            PropDestinationUrl: window.location.href,
            PropReferringUrl: (ttt._dl && ttt._dl.PropDestinationUrl) || window.document.referrer,
        });
        if (ttt._clearParamsFilter) {
            ttt._clearParams(ttt._clearParamsFilter);
        }
        return ttt._rawEvent('pageview', Object.assign({}, ttt._dl, contextData));
    };
    ttt.event = function (name, contextData) {
        assertInitialization();
        var props = Object.assign({}, ttt._dl, contextData);
        return ttt._rawEvent(name, props);
    };
    ttt.nwtEvent = function (eventData) {
        assertInitialization();
        var screenUrl = determineActualUrl(ttt._dl.PropDestinationUrl, window.location.href);
        eventData.id = uuid();
        nwt.track(eventData, ttt._env, screenUrl);
    };
    ttt.withPerformanceMetrics = function (action) {
        assertInitialization();
        return new Promise(function (resolve) {
            var resolveWithMetrics = function () {
                setTimeout(function () {
                    var times = getPerformanceMetrics(window);
                    var metrics = Object.keys(times)
                        .map(function (name) { return "".concat(name, "=").concat(times[name]); })
                        .join(';');
                    var props = { PropTechnicalPerformance: metrics };
                    action && action(props);
                    resolve(props);
                }, 0);
            };
            if ((window.document && window.document.readyState === 'complete') ||
                !window.addEventListener) {
                resolveWithMetrics();
            }
            else {
                var onReady_1 = function () {
                    resolveWithMetrics();
                    window.removeEventListener('load', onReady_1, true);
                };
                window.addEventListener('load', onReady_1, true);
            }
        });
    };
    ttt.publish = function (action, payload, options) {
        if (options === void 0) { options = {}; }
        assertInitialization();
        return ttt._sandbox.publish(action, payload, options);
    };
    ttt.subscribe = function (name, callback) {
        assertInitialization();
        return ttt._sandbox.subscribe(name, callback);
    };
    ttt._clearParams = function (filter) {
        assertInitialization();
        return clearParams(filter);
    };
    ttt._rawEvent = function (event, props) {
        var _a;
        assertInitialization();
        var hostname = getSecondLevelDomain();
        var options = {};
        if (hostname !== null) {
            options.domain = hostname;
        }
        var prevPage = getCookie('prevPage') || 'FirstPageOfVisit';
        if (props.pageName) {
            // @ts-ignore JS function not properly typed yet
            setCookie('prevPage', props.pageName, 1800000, options);
        }
        props = Object.assign({}, props, {
            PropLastPage: prevPage,
            PropLastPageCookieSet: 1, // can be removed once ttt 1.8.1 is rolled out completely
        });
        if (DEDUPLICATION_ID_ALLOWLIST.includes((_a = props.pageName) === null || _a === void 0 ? void 0 : _a.split('/')[0])) {
            props = Object.assign(props, {
                PropDeduplicationId: uuid(),
            });
        }
        return Promise.all([
            abacus.track(ttt._env, event, props),
            ttt._sandbox.publish('stm:track', { event: event, props: props }),
        ]);
    };
    ttt._rawPageview = ttt._rawEvent.bind(null, 'pageview');
    ttt._unload = function () {
        return ttt._sandbox.unload();
    };
    return ttt;
};
export default setupTracking;
export { splitConfigs, buildTTTConfig, buildSTMConfig, createClearParamsFilter, };
