var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import config from './config.json';
var getXingboxConfig = function (env) {
    return env === 'xingbox' ? { host: document.location.hostname } : {};
};
var getConfig = function (env) { return (__assign(__assign({}, (config[env] || config.default)), getXingboxConfig(env))); };
export default getConfig;
