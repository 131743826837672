import { CLIENTSIDE_VISITORCOOKIE_NAME } from '../constants';
import { getCookie, setCookie } from './cookies';
import { v4 as uuid } from 'uuid';
import getSecondLevelDomain from './getSecondLevelDomain';
var getClientsideVisitorId = function () {
    // get or generate visitorId
    var visitorId = getCookie(CLIENTSIDE_VISITORCOOKIE_NAME) || uuid();
    // set visitor_id cookie
    var hostname = getSecondLevelDomain();
    var options = {};
    if (hostname !== null) {
        options.domain = hostname;
    }
    setCookie(CLIENTSIDE_VISITORCOOKIE_NAME, visitorId, 1000 * 60 * 60 * 24 * 365 * 1, options);
    return visitorId;
};
export default getClientsideVisitorId;
