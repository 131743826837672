var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getConfig from './config';
import sendBeacon, { isBeaconApiSupported } from '../helper/sendBeacon';
import postTracking from '../helper/postTracking';
import getUnixTimestamp from '../helper/getUnixTimestamp';
import { Future, getCookie, setCookie, getSecondLevelDomain, uuid, getClientsideVisitorId, getTrackingEndpointUrl, } from '../../../lib/';
import { CLIENTSIDE_VISITORCOOKIE_DOMAIN_ALLOWLIST, ABACUS_TRACKING_PATH, } from '../../../constants';
import { TTT_VERSION as version } from '../../../version';
var getBrowserWidth = function () {
    var document = window.document;
    if (window.innerWidth) {
        return window.innerWidth;
    }
    else if (document && document.documentElement) {
        return document.documentElement.offsetWidth;
    }
    return '';
};
var getBrowserHeight = function () {
    var document = window.document;
    if (window.innerHeight) {
        return window.innerHeight;
    }
    else if (document && document.documentElement) {
        return document.documentElement.offsetHeight;
    }
    return '';
};
var getScreenResolution = function () {
    if (window.screen) {
        return "".concat(window.screen.width, "x").concat(window.screen.height);
    }
    return '';
};
var getFilteredProps = function (props, filterKeys) {
    if (filterKeys === void 0) { filterKeys = ['PropDestinationUrl', 'PropReferringUrl', 'pageName']; }
    var properties = {};
    Object.keys(props)
        .filter(function (key) {
        return filterKeys.indexOf(key) === -1;
    })
        .forEach(function (key) {
        var _a, _b;
        if (Array.isArray(props[key])) {
            Object.assign(properties, (_a = {}, _a[key] = props[key].join(','), _a));
        }
        else {
            Object.assign(properties, (_b = {}, _b[key] = props[key], _b));
        }
    });
    return properties;
};
var tracker = {};
var pageviewFuture = new Future();
export var xingDataFuture = new Future();
var firstEvent = true;
export var resetFirstEvent = function () {
    firstEvent = true;
};
var sendEvent = function (props, event, url) {
    var PropAppId = "ttt@".concat(version).concat(props.PropAppId ? ',' + props.PropAppId : '');
    return new Promise(function (resolve) {
        var payload = {
            clientTimestamp: getUnixTimestamp(),
            pageName: props.pageName,
            location: props.PropDestinationUrl,
            referrer: props.PropReferringUrl || undefined,
            properties: getFilteredProps(__assign(__assign({}, props), { PropAppId: PropAppId })),
            browserWidth: getBrowserWidth(),
            browserHeight: getBrowserHeight(),
            resolution: getScreenResolution(),
            type: 'web',
            eventName: event,
        };
        if (isBeaconApiSupported()) {
            resolve(sendBeacon(url, payload));
        }
        else {
            resolve(postTracking(url, payload));
        }
    });
};
var setClientsideVisitorIdCookie = function (domain) {
    var cookieName = 'c_visitor_id';
    var options = {
        domain: domain,
    };
    if (!getCookie(cookieName)) {
        setCookie(cookieName, uuid(), 1000 * 5, options); // Cookie expires after 5 seconds
    }
};
var postponeActionEvent = function (props, event, endpointUrl) {
    pageviewFuture.then(function (pageviewProps) {
        var eventProps = __assign({}, props);
        ['pageName', 'PropChannel', 'PropApplication'].forEach(function (element) {
            if (!eventProps[element] || eventProps[element] === 'undefined') {
                eventProps[element] = pageviewProps[element];
            }
        });
        sendEvent(eventProps, event, endpointUrl);
    });
};
tracker.track = function (env, event, props) {
    var endpointUrl = getTrackingEndpointUrl(getConfig(env), env, ABACUS_TRACKING_PATH);
    if (firstEvent) {
        setClientsideVisitorIdCookie(getSecondLevelDomain());
        firstEvent = false;
    }
    if (CLIENTSIDE_VISITORCOOKIE_DOMAIN_ALLOWLIST.includes(getSecondLevelDomain())) {
        props = Object.assign({}, props, {
            csodVisitorId: getClientsideVisitorId(),
        });
    }
    if (event === 'pageview' || !props.PropPostponeAction) {
        pageviewFuture.resolve(props);
        sendEvent(props, event, endpointUrl);
    }
    else {
        postponeActionEvent(props, event, endpointUrl);
    }
};
export default tracker;
