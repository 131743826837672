var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { v4 as uuid } from 'uuid';
var _key = 'stm-v1';
var buildMessage = function (message) {
    var id = uuid();
    return __assign({ _key: _key, id: id }, message);
};
var validateMessage = function (message, action) {
    return (typeof message === 'object' &&
        message._key === _key &&
        message.action === action);
};
var postMessage = function (target, action, payload, overrides) {
    if (overrides === void 0) { overrides = {}; }
    return new Promise(function (resolve) {
        target.postMessage(JSON.stringify(buildMessage(__assign({ action: action, payload: payload }, overrides))), '*');
        setTimeout(resolve, 0);
    });
};
export { buildMessage, validateMessage, postMessage, _key };
